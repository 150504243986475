<script setup>
import { useShiftStore } from '@/stores/shift';
import { inject } from 'vue';
import { ref } from 'vue';
import { capitalFirstLetter } from '@/utilities';


// Components
import Icon from '@/Components/Icon.vue';
import Button from '@/Components/Button/Button.vue';

// Partials
import Date from './Date.vue';

// Icons
import Left from '@i/expand_more-left.svg';
import Right from '@i/expand_more-right.svg';

const props = defineProps({
    form: Object,
    additionalStartDates: Object,
    date: String,
});

const dayjs = inject('dayjs');
const emits = defineEmits(['update:additionalStartDates']);
const store = useShiftStore();

const addAdditionalStartDates = (date) => {
    const isThere = props.additionalStartDates.find((dateInArray) => dateInArray.isSame(date));

    if (isThere) {
        const allDates = props.additionalStartDates.filter((dateInArray) => !dateInArray.isSame(date));

        emits('update:additionalStartDates', allDates);
    } else {
        const allDates = [...props.additionalStartDates, date];

        emits('update:additionalStartDates', allDates);
    }
};
</script>

<template>


    <div class="mb-4 flex-between">
        <span @click="store.selectedDateString = store.date.subtract(1, 'month').startOf('month').format('YYYY-MM-DD')"
            class="gap-2 cursor-pointer flex-center">
            <Icon :icon="Left" class="relative w-2 h-auto left-3" />
        </span>
        <div class="text-base font-bold">
            {{ capitalFirstLetter(dayjs().localeData().months()[store.date.format('M') - 1]) }}
            {{ store.date.format('YYYY') }}
        </div>
        <span @click="store.selectedDateString = store.date.add(1, 'month').startOf('month').format('YYYY-MM-DD')"
            class="gap-2 cursor-pointer flex-center">
            <Icon :icon="Right" class="relative w-2 h-auto right-3" />
        </span>
    </div>

    <div class="grid grid-cols-7 gap-3 py-1 text-sm text-gray-lightmedium">
        <div class="flex-center">{{ $t('M') }}</div>
        <div class="flex-center">{{ $t('T') }}</div>
        <div class="flex-center">{{ $t('W') }}</div>
        <div class="flex-center">{{ $t('T') }}</div>
        <div class="flex-center">{{ $t('F') }}</div>
        <div class="flex-center">{{ $t('S') }}</div>
        <div class="flex-center">{{ $t('S') }}</div>
    </div>

    <div class="mb-3 border rounded border-gray-medium">
        <div v-for="(dates, week) in store.calDatesPerWeek" :key="week"
            class="grid grid-cols-7 border-b border-gray-medium min-h-12">
            <template v-for="date in dates" :key="date.format()">

                <Date :date="date" :selected="additionalStartDates.find((dateInArray) => dateInArray.isSame(date))"
                    @dateWasClicked="addAdditionalStartDates(date)" />
            </template>
        </div>
    </div>

    <div>
        <p class="mb-3" v-if="additionalStartDates.length">{{ $t('Selected') }}</p>
        <p class="flex flex-wrap gap-2 mb-8 text-base font-bold leading-3">
        <div v-for="(date, index) in additionalStartDates" :key="index">
            {{ date.format('dd DD-MM-YYYY') }}<span v-if="index != Object.keys(additionalStartDates).length - 1">,
            </span>

        </div>
        </p>
    </div>

</template>
